<template>
  <v-data-table
    :headers="invoicesTableHeaders"
    :items="invoices"
    :options.sync="invoicesPagination"
    :server-items-length="totalInvoicesCount"
    :loading="loadingAll || loadingInvoices"
    disable-sort
    class="fill-height last-table-cell-fit-content"
    data-cy="invoice-table"
  >
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-toolbar-title v-t="'invoices.invoices'" />
        <v-alert
          v-if="missingPaymentsSum"
          :color="zeroMissingPaymentSum ? 'success' : 'error'"
          dense
          outlined
          type="error"
          class="mb-0 ml-auto"
        >
          <div class="d-inline-block sum-title">
            <span v-t="'invoices.missingPayments'" />
          </div>
          <span class="d-inline-block ml-2">
            {{ missingPaymentsSum }}
          </span>
        </v-alert>
      </v-toolbar>
      <v-divider />
    </template>
    <template #header.actions>
      <v-btn
        v-if="totalInvoicesCount > 1"
        depressed
        outlined
        rounded
        floating
        color="primary"
        class="float-right"
        data-cy="download-all-icon"
        style="padding: 6px !important; min-width: 0; margin-right: -10px;"
        @click="downloadAllInvoices(clientId)"
      >
        <v-icon rounded>
          mdi-download-multiple
        </v-icon>
        <v-progress-circular
          v-if="allInvoicesDownloading"
          class="ml-2"
          :size="20"
          :width="3"
          indeterminate
        />
      </v-btn>
      <span
        v-else
        v-t="'actions'"
      />
    </template>
    <template #item.issuedDate="{ item }">
      {{ item.issuedDate | formatDate }}
    </template>
    <template #item.number="{ item }">
      <v-tooltip top>
        <template #activator="{ on }">
          <span v-on="on">{{ item.number }}</span>
        </template>
        <span>{{ item.createDate | formatDate }}</span>
      </v-tooltip>
    </template>
    <template #item.amount="{ item }">
      <div class="amount-cell-content">
        {{ item.amount || '' }}
      </div>
    </template>
    <template #item.status="{ item }">
      <div class="invoice-table-status">
        <v-icon
          color="success"
          class="mr-1"
        >
          {{ getStatusIcon(item.status) }}
        </v-icon>

        {{ item.status }}
      </div>
    </template>
    <template #item.actions="{ item }">
      <div class="text-right">
        <material-invoices-and-quotations-pay-modal-window
          v-if="showPayButton(item)"
          :button-name="$t('invoices.payOnline')"
          :order="item.order"
          :client-id="clientId"
          :invoice-to-partner="item.invoiceToPartner"
        />
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              class="mr-n1 invoices-download-btn"
              data-cy="download-icon"
              v-on="on"
              @click.native="onClickDownload(item.id)"
            >
              {{ downloadIcon }}
            </v-icon>
          </template>
          <span v-t="'invoices.download'" />
        </v-tooltip>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import {
  STATUS_ISSUED,
  STATUS_PAID,
  DOWNLOAD_DOCUMENT_ICON
} from '../../../constants/app'

export default {
  props: {
    clientId: {
      type: [String, Number],
      required: true
    }
  },
  data: () => {
    return {
      issuedStatus: STATUS_ISSUED,
      paidStatus: STATUS_PAID,
      downloadIcon: DOWNLOAD_DOCUMENT_ICON
    }
  },
  computed: {
    ...mapState('invoicesAndQuotations', ['allInvoicesDownloading', 'loadingAll', 'loadingInvoices']),
    ...mapGetters('client', ['clientById']),
    ...mapGetters('invoicesAndQuotations', {
      invoicesGetter: 'invoices',
      paginationGetter: 'pagination',
      allInvoicesIds: 'allInvoicesIds',
      missingPaymentsSumGetter: 'missingPaymentsSum'
    }),
    missingPaymentsSum () {
      return this.missingPaymentsSumGetter(this.$route.params.clientId)
    },
    zeroMissingPaymentSum () {
      const amount = this.missingPaymentsSum && this.missingPaymentsSum.split(' ')[1]
      return amount === '0.0'
    },
    invoices () {
      return this.invoicesGetter(this.clientId) ? this.invoicesGetter(this.clientId) : []
    },
    totalInvoicesCount () {
      return this.allInvoicesIds(this.clientId).length
    },
    invoicesPagination: {
      get: function () {
        return this.paginationGetter(this.clientId)
      },
      set: function (newPagination) {
        if (newPagination && this.isPaginationChanged(newPagination, this.invoicesPagination)) {
          this.setValue({
            id: this.clientId,
            field: 'pagination',
            payload: newPagination
          })
        }
      }
    },
    invoicesTableHeaders () {
      return [
        { text: this.$i18n.t('invoices.status'), value: 'status' },
        { text: this.$i18n.t('invoices.invoiceNr'), value: 'number' },
        { text: this.$i18n.t('invoices.dateIssued'), value: 'issuedDate' },
        {
          text: this.$i18n.t('invoices.amountWithoutTax'),
          value: 'amount',
          align: 'end'
        },
        {
          text: this.$i18n.t('invoices.downloadAll'),
          value: 'actions',
          align: 'right',
          sortable: false
        }
      ]
    }
  },
  watch: {
    invoicesPagination: {
      handler (newPagination, oldPagination) {
        if (this.isPaginationChanged(newPagination, oldPagination)) {
          this.getInvoices({
            page: newPagination.page,
            invoicesPerPage: newPagination.itemsPerPage,
            clientId: this.clientId
          })
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('invoicesAndQuotations', ['getInvoices', 'downloadInvoicePDF', 'downloadAllInvoices']),
    ...mapMutations('invoicesAndQuotations', ['setValue']),
    onClickDownload: async function (invoiceId) {
      this.downloadInvoicePDF({
        clientId: this.$route.params.clientId,
        invoiceId
      })
    },
    isPaginationChanged (newPagination, oldPagination) {
      return newPagination.page !== oldPagination.page || newPagination.itemsPerPage !== oldPagination.itemsPerPage
    },
    showPayButton (invoice) {
      return (
        invoice.status.toUpperCase() === STATUS_ISSUED &&
        invoice.order &&
        invoice.order.calculoidData
      )
    },
    getStatusIcon: (status) => status === STATUS_PAID ? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline'
  }
}
</script>

<style scoped>
.invoice-table-status {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.invoices-download-btn {
  cursor: pointer;
}
</style>
