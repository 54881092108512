var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"fill-height last-table-cell-fit-content",attrs:{"headers":_vm.invoicesTableHeaders,"items":_vm.invoices,"options":_vm.invoicesPagination,"server-items-length":_vm.totalInvoicesCount,"loading":_vm.loadingAll || _vm.loadingInvoices,"disable-sort":""},on:{"update:options":function($event){_vm.invoicesPagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{directives:[{name:"t",rawName:"v-t",value:('invoices.invoices'),expression:"'invoices.invoices'"}]}),(_vm.missingPaymentsSum)?_c('v-alert',{staticClass:"mb-0 ml-auto",attrs:{"color":_vm.zeroMissingPaymentSum ? 'success' : 'error',"dense":"","outlined":"","type":"error"}},[_c('div',{staticClass:"d-inline-block sum-title"},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('invoices.missingPayments'),expression:"'invoices.missingPayments'"}]})]),_c('span',{staticClass:"d-inline-block ml-2"},[_vm._v(" "+_vm._s(_vm.missingPaymentsSum)+" ")])]):_vm._e()],1),_c('v-divider')]},proxy:true},{key:"header.actions",fn:function(){return [(_vm.totalInvoicesCount > 1)?_c('v-btn',{staticClass:"float-right",staticStyle:{"padding":"6px !important","min-width":"0","margin-right":"-10px"},attrs:{"depressed":"","outlined":"","rounded":"","floating":"","color":"primary"},on:{"click":function($event){return _vm.downloadAllInvoices(_vm.clientId)}}},[_c('v-icon',{attrs:{"rounded":""}},[_vm._v(" mdi-download-multiple ")]),(_vm.allInvoicesDownloading)?_c('v-progress-circular',{staticClass:"ml-2",attrs:{"size":20,"width":3,"indeterminate":""}}):_vm._e()],1):_c('span',{directives:[{name:"t",rawName:"v-t",value:('actions'),expression:"'actions'"}]})]},proxy:true},{key:"item.issuedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.issuedDate))+" ")]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.number))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.createDate)))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"amount-cell-content"},[_vm._v(" "+_vm._s(item.amount || '')+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"invoice-table-status"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.getStatusIcon(item.status))+" ")]),_vm._v(" "+_vm._s(item.status)+" ")],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[(_vm.showPayButton(item))?_c('material-invoices-and-quotations-pay-modal-window',{attrs:{"button-name":_vm.$t('invoices.payOnline'),"order":item.order,"client-id":_vm.clientId,"invoice-to-partner":item.invoiceToPartner}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-n1 invoices-download-btn",nativeOn:{"click":function($event){return _vm.onClickDownload(item.id)}}},on),[_vm._v(" "+_vm._s(_vm.downloadIcon)+" ")])]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('invoices.download'),expression:"'invoices.download'"}]})])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }